import { Car } from '@/models/car.model';

export const cars: Car[] = [
  {
    id: 'ev_elw',
    name: 'ELW 1',
    // type: 'Einsatzleitwagen',
    imgs: [
      require('@/assets/fz/ev_elw/preview.jpg'),
    ],
    previewImg: require('@/assets/fz/ev_elw/preview.jpg'),
    fullname: 'EVE1-ELW1-1',
    function: 'Mobile Einsatz- und Kommunikationszentrale, Lenkung der Einsatzkräfte',
    // equipment: 'Funkgeräte, Mobiles Internet, Fax, Telefon, Laptop, Stromerzeuger, Gaswarngerät, Karten und Pläne',
    // constructionYear: '2015',
    firstRegistration: '2021',
    crew: '1:2',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Spinter 4X4'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'GSF Sonderfahrzeugbau GmbH'
      },
      {
        name: 'Leistung',
        value: '120 KW / 163 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '3.8 t'
      }
    ]
  },
  {
    id: 'ev_hlf202',
    name: 'HLF 20-2',
    // type: 'Hilfeleistungslöschgruppenfahrzeug',
    imgs: [
      require('@/assets/fz/ev_hlf202/1.jpg'),
      require('@/assets/fz/ev_hlf202/2.jpg'),
      require('@/assets/fz/ev_hlf202/3.jpg'),
      require('@/assets/fz/ev_hlf202/4.jpg'),
      require('@/assets/fz/ev_hlf202/5.jpg'),
      require('@/assets/fz/ev_hlf202/6.jpg'),
      require('@/assets/fz/ev_hlf202/7.jpg')
    ],
    previewImg: require('@/assets/fz/ev_hlf202/preview.jpg'),
    fullname: 'EVE1-HLF20-2',
    function: 'Fahrzeug hauptsächlich zur Brandbekämpfung und technischen Hilfeleistung',
    // equipment: '2000 Liter/min Pumpenleistung, 2000 Liter Löschwassertank, Schaumdirektdosierung über Schaumtank, Generator, Lichtmast, Glasschneider für Verbundglas, hydraulisches Rettungsgerät (Schere, Spreizer, Rettungszylinder) auf Akkubasis, Überdrucklüfter, Atemschutzgeräte, sowie zahlreiche Zusatzeinrichtungen',
    // constructionYear: '2014',
    firstRegistration: '08.05.2014',
    crew: '1:8',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Artego'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Albert Ziegler GmbH'
      },
      {
        name: 'Leistung',
        value: '210 KW / 286 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '16 t'
      }
    ]
  },
  {
    id: 'ev_dlk',
    name: 'DLK 23/12',
    // type: 'Drehleiter mit Korb',
    imgs: [
      require('@/assets/fz/ev_dlk/1.jpg'),
      require('@/assets/fz/ev_dlk/2.jpg'),
      require('@/assets/fz/ev_dlk/3.jpg'),
      require('@/assets/fz/ev_dlk/4.jpg'),
      require('@/assets/fz/ev_dlk/5.jpg'),
      require('@/assets/fz/ev_dlk/6.jpg'),
      require('@/assets/fz/ev_dlk/7.jpg'),
      require('@/assets/fz/ev_dlk/8.jpg'),
      require('@/assets/fz/ev_dlk/9.jpg'),
      require('@/assets/fz/ev_dlk/10.jpg'),
      require('@/assets/fz/ev_dlk/11.jpg'),
      require('@/assets/fz/ev_dlk/12.jpg')
    ],
    previewImg: require('@/assets/fz/ev_dlk/preview.jpg'),
    fullname: 'EVE1-DLK23-1',
    function: 'Personenrettung, Brandbekämpfung',
    // equipment: 'Drehleiter mit Korb, Gesamtlänge des Leiterparks 30m, Nennrettungshöhe 23 m bei 12 m Ausladung. Metz Leiterpark aufgebaut auf einem Mercedes Fahrgestell 1422. Zusatzbeladung 5 kVA Stromaggregat und Lüfter.',
    // constructionYear: '1992',
    firstRegistration: '25.11.1992',
    crew: '1:2',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: '1422'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Metz Feuerwehrgeräte GmbH'
      },
      {
        name: 'Leistung',
        value: '210 KW / 286 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '14,2 t'
      }
    ]
  },
  {
    id: 'ev_hlf201',
    name: 'HLF 20-1',
    // type: 'Hilfeleistungslöschgruppenfahrzeug',
    imgs: [
      require('@/assets/fz/ev_hlf201/1.jpg'),
      require('@/assets/fz/ev_hlf201/2.jpg'),
      require('@/assets/fz/ev_hlf201/3.jpg'),
      require('@/assets/fz/ev_hlf201/4.jpg'),
      require('@/assets/fz/ev_hlf201/5.jpg'),
      require('@/assets/fz/ev_hlf201/6.jpg')
    ],
    previewImg: require('@/assets/fz/ev_hlf201/preview.jpg'),
    fullname: 'EVE1-HLF20-1',
    function: 'Fahrzeug hauptsächlich zur Brandbekämpfung und technischen Hilfeleistung',
    // equipment: '1600 Liter/min Pumpenleistung, 1650 Liter Löschwassertank, Schaumbildner in Kanistern, Generator, Lichtmast, Glasschneider für Verbundglas, hydraulisches Rettungsgerät (Schere, Spreizer, Rettungszylinder) Überdrucklüfter, Atemschutzgeräte, sowie zahlreiche Zusatzeinrichtungen',
    // constructionYear: '1997',
    firstRegistration: '20.05.1997',
    crew: '1:8',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: '1224'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Albert Ziegler GmbH'
      },
      {
        name: 'Leistung',
        value: '177 KW / 241 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '13,5 t'
      }
    ]
  },
  // {
  //   id: 'ev_rw',
  //   name: 'RW',
  //   type: 'Rüstwagen',
  //   imgs: [require('@/assets/fz/ev_rw/1.jpg')],
  //   fullname: '-',
  //   function: '',
  //   equipment: '',
  //   constructionYear: '',
  //   crew: '1:2',
  //   description: ''
  // },
  {
    id: 'ev_tw',
    name: 'TW-12000',
    // type: 'Tankwagen',
    imgs: [
      require('@/assets/fz/ev_tw/1.jpg'),
      require('@/assets/fz/ev_tw/2.jpg'),
      require('@/assets/fz/ev_tw/3.jpg'),
      require('@/assets/fz/ev_tw/4.jpg'),
      require('@/assets/fz/ev_tw/5.jpg'),
      require('@/assets/fz/ev_tw/6.jpg')
    ],
    previewImg: require('@/assets/fz/ev_tw/preview.jpg'),
    fullname: 'EVE1-TW12000-1',
    function: 'Transport großer Mengen Wasser (12.000 l)',
    // equipment: '-',
    // constructionYear: '2011',
    firstRegistration: '20.12.2011',
    crew: '1:2',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'MAN'
      },
      {
        name: 'Modell',
        value: 'TGS 26.480'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Stricker'
      },
      {
        name: 'Leistung',
        value: '353 KW / 480 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '26 t'
      }
    ]
  },
  {
    id: 'ev_glw',
    name: 'GW-L2',
    // type: 'Gerätewagen Logistik',
    imgs: [
      require('@/assets/fz/ev_gwl/1.jpg'),
      require('@/assets/fz/ev_gwl/2.jpg'),
      require('@/assets/fz/ev_gwl/3.jpg'),
      require('@/assets/fz/ev_gwl/4.jpg'),
      require('@/assets/fz/ev_gwl/5.jpg')
    ],
    previewImg: require('@/assets/fz/ev_gwl/preview.jpg'),
    fullname: 'EVE1-GW-L2-1',
    function: 'Logistiker',
    // equipment: 'Rollcontainer für Schaum/ABC/Öl, Schlauchmaterial für lange Wegstrecken, Pumpen',
    // constructionYear: '2019',
    firstRegistration: '25.06.2019',
    crew: '1:5',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'MAN'
      },
      {
        name: 'Modell',
        value: 'TGM 18.340 4x4'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'FREYTAG Karosseriebau GmbH & Co. KG'
      },
      {
        name: 'Leistung',
        value: '250 KW / 340 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '16,5 t'
      }
    ]
  },
  {
    id: 'ev_mtf',
    name: 'MTF',
    // type: 'Mannschaftstransportfahrzeug',
    imgs: [
      require('@/assets/fz/ev_mtw/preview.jpg'),
      require('@/assets/fz/ev_mtw/1.jpg')
    ],
    previewImg: require('@/assets/fz/ev_mtw/preview.jpg'),
    fullname: 'EVE1-MTF-1',
    function: 'Transport einer Mannschaft',
    // equipment: 'In der Kombination mit dem Anhänger Multifunktional einsetzbar.',
    // constructionYear: '2021',
    firstRegistration: '08.03.2021',
    crew: '1:8',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Sprinter'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Selectric'
      },
      {
        name: 'Leistung',
        value: '120 KW / 163 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '3,5 t'
      }
    ]
  },
  {
    id: 'ev_anh',
    name: 'Anhänger',
    // type: 'Anhänger',
    imgs: [require('@/assets/fz/ev_anhaenger/1.jpg')],
    previewImg: require('@/assets/fz/ev_anhaenger/preview.jpg'),
    fullname: '112',
    function: 'Anhänger / Logistiker',
    // equipment: 'großer Laderaum, Lochschienen mit Arretierungssystem',
    // constructionYear: '2002',
    firstRegistration: '12.01.2004',
    crew: '-',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'WEKO Maschinen- und Anhängerbau'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '2,0 t'
      }
    ]
  },
  {
    id: 'ev_rtw',
    name: 'RTW',
    // type: 'Mannschaftstransportfahrzeug',
    imgs: [
      require('@/assets/fz/ev_rtw/preview.jpg')
    ],
    previewImg: require('@/assets/fz/ev_rtw/preview.jpg'),
    fullname: 'DRK-EVE1 RTW1',
    function: 'Notfallrettung und Krankentransport',
    // equipment: 'In der Kombination mit dem Anhänger Multifunktional einsetzbar.',
    // constructionYear: '2021',
    firstRegistration: '18.09.2012',
    crew: '1:1',
    peculiarity: 'Fahrzeug wurde 2015 in Dienst gestellt',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Sprinter'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Wietmarscher Ambulanz- und Sonderfahrzeug GmbH'
      },
      {
        name: 'Leistung',
        value: '140 KW / 190 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '5 t'
      }
    ]
  },
  {
    id: 'ev_rw',
    name: 'GW-L1',
    // type: 'Mannschaftstransportfahrzeug',
    imgs: [
      require('@/assets/fz/ev_rw/preview.jpg')
    ],
    previewImg: require('@/assets/fz/ev_rw/preview.jpg'),
    fullname: 'EVE1-GW-L1-1',
    function: 'Fahrzeug zur technischen Hilfeleistung',
    // equipment: 'In der Kombination mit dem Anhänger Multifunktional einsetzbar.',
    // constructionYear: '2021',
    firstRegistration: '28.06.1990',
    crew: '1:2',
    peculiarity: 'Fahrzeug wurde 2023 als GW-L1 in Dienst gestellt',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Unimog U 1300'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Wackenhut'
      },
      {
        name: 'Leistung',
        value: '100 KW / 136 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '7,49 t'
      }
    ]
  },
];

export const carsAl: Car[] = [
  {
    id: 'al_elw',
    name: 'ELW',
    // type: 'Einsatzleitwagen',
    imgs: [
      require('@/assets/fz/al_elw/preview.jpg')
    ],
    previewImg: require('@/assets/fz/al_elw/preview.jpg'),
    fullname: 'EVE2-ELW1-1',
    function: 'Mobile Einsatz- und Kommunikationszentrale, Lenkung der Einsatzkräfte',
    // equipment: 'Funkgeräte, mobiles Internet, Fax, Telefon, PC, Generator, Gaswarngerät, Pläne und Kartenmaterial',
    // constructionYear: '2008',
    firstRegistration: '28.06.2007',
    crew: '1:2',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Sprinter'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'LCD Elektro'
      },
      {
        name: 'Leistung',
        value: '80 KW / 110 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '3,5 t'
      }
    ]
  },
  {
    id: 'al_hlf202',
    name: 'HLF 20',
    // type: 'Hilfeleistungslöschgruppenfahrzeug',
    imgs: [
      require('@/assets/fz/al_hlf20/1.jpg'),
      require('@/assets/fz/al_hlf20/2.jpg'),
      require('@/assets/fz/al_hlf20/3.jpg'),
      require('@/assets/fz/al_hlf20/4.jpg'),
      require('@/assets/fz/al_hlf20/5.jpg'),
      require('@/assets/fz/al_hlf20/6.jpg'),
      require('@/assets/fz/al_hlf20/7.jpg'),
      require('@/assets/fz/al_hlf20/8.jpg'),
      require('@/assets/fz/al_hlf20/9.jpg')
    ],
    previewImg: require('@/assets/fz/al_hlf20/preview.jpg'),
    fullname: 'EVE2-HLF20-1',
    function: 'Fahrzeug hauptsächlich zur Brandbekämpfung und technischen Hilfeleistung',
    // equipment: 'Pumpenleistung 2000 Liter/min, Löschwassertank mit 2230 Liter, Generator, Lichtmast, hydraulisches Rettungsgerät auf Akkubasis, Überdrucklüfter, Atemschutzgeräte, zahlreiche Zusatzeinrichtungen',
    // constructionYear: '2008',
    firstRegistration: '24.06.2008',
    crew: '1:8',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Atego 1329'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Albert Ziegler GmbH'
      },
      {
        name: 'Leistung',
        value: '210 KW / 286 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '14,5 t'
      }
    ]
  },
  {
    id: 'al_lf20_kats',
    name: 'LF 20 KatS',
    // type: 'Löschgruppenfahrzeug für den Katastrophenschutz',
    imgs: [
      require('@/assets/fz/al_lf20kats/1.jpg'),
      require('@/assets/fz/al_lf20kats/2.jpg'),
      require('@/assets/fz/al_lf20kats/3.jpg'),
      require('@/assets/fz/al_lf20kats/4.jpg'),
      require('@/assets/fz/al_lf20kats/5.jpg')
    ],
    previewImg: require('@/assets/fz/al_lf20kats/preview.jpg'),
    fullname: 'EVE2-LF20KatS-1',
    function: 'Fahrzeug hauptsächlich zur Brandbekämpfung und Wasserversorgung/-förderung',
    // equipment: 'Pumpenleistung 2000 Liter/min, Löschwassertank mit 1000 Liter, 480m B-Schlauch in Buchten zum Verlegen bei der Fahrt, Tragkraftspritze, Generator, Lichtmast, Faltbehälter mit 15000 Liter Fassungsvermögen, Armaturen TEO-Löschwasserkomponente',
    // constructionYear: '2017',
    firstRegistration: '27.11.2017',
    crew: '1:8',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz Atego 1329'
      },
      {
        name: 'Modell',
        value: 'Atego 1329'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Schlingmann GmbH & Co. KG'
      },
      {
        name: 'Leistung',
        value: '210 KW / 286 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '13,5 t'
      }
    ]
  },
  {
    id: 'al_mtf',
    name: 'MTF',
    // type: 'Mannschaftstransportfahrzeug',
    imgs: [
      require('@/assets/fz/al_mtw/preview.jpg')
    ],
    previewImg: require('@/assets/fz/al_mtw/preview.jpg'),
    fullname: 'EVE2-MTF-1',
    function: 'Transport von Mannschaft und Gerät',
    // equipment: 'Vielseitig einsetzbar',
    // constructionYear: '2021',
    firstRegistration: '08.03.2021',
    crew: '1:8',
    description: '',
    details: [
      {
        name: 'Hersteller',
        value: 'Mercedes Benz'
      },
      {
        name: 'Modell',
        value: 'Sprinter'
      },
      {
        name: 'Aufbau-/Ausbauhersteller',
        value: 'Selectric'
      },
      {
        name: 'Leistung',
        value: '120 KW / 163 PS'
      },
      {
        name: 'Zulässiges Gesamtgewicht',
        value: '3,5 t'
      }
    ]
  },
  // {
  //   id: 'al_anh',
  //   name: 'Anhänger',
  //   // type: 'Schlauchanhänger 500',
  //   imgs: [
  //     require('@/assets/fz/al_anhaenger/1.jpg'),
  //     require('@/assets/fz/al_anhaenger/2.jpg'),
  //     require('@/assets/fz/al_anhaenger/3.jpg')
  //   ],
  //   previewImg: require('@/assets/fz/al_anhaenger/preview.jpg'),
  //   fullname: 'SA 500',
  //   function: 'Logistiker',
  //   peculiarity: 'Anhänger für Logistik',
  //   // equipment: 'B - Druckschläuche, Standrohr',
  //   // constructionYear: '1989',
  //   firstRegistration: '13.04.1973',
  //   crew: '-',
  //   description: '',
  //   details: [
  //     {
  //       name: 'Hersteller',
  //       value: 'Eigenbau'
  //     },
  //     {
  //       name: 'Zulässiges Gesamtgewicht',
  //       value: '1,3 t'
  //     }
  //   ]
  // }
];

export const allCars = [...cars, ...carsAl];

export type CarsDict = { [key: string]: Car };

export const carsDict: CarsDict = allCars.reduce((dict, car) => {
  dict[car.id] = car;
  return dict;
}, {} as CarsDict);

export const historyCars: Car[] = [];
