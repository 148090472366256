
































import { defineComponent, PropType, ref } from '@vue/composition-api';

import { cars, carsAl, allCars } from '@/data/car.data';
import { LzId } from '@/models/car.model';

import CarsRow from '@/components/CarsRow.vue';

export default defineComponent({
  name: 'CarsCombinedView',
  props: {
    lz: {
      type: String as PropType<LzId>,
      default: () => 'all'
    }
  },
  components: {
    CarsRow
  },
  setup: props => {
    let initialTab = 0;
    if (props.lz === 'ev') {
      initialTab = 1;
    } else if (props.lz === 'al') {
      initialTab = 2;
    }

    const carTab = ref(initialTab);
    const carTabDatas = ref([
      {
        name: 'Alle',
        cars: allCars
      },
      {
        name: 'Everswinkel',
        cars: cars
      },
      {
        name: 'Alverskirchen',
        cars: carsAl
      }
    ]);

    return {
      carTab,
      carTabDatas
    };
  }
});
