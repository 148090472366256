









































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CarsRow',
  props: {
    cars: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  setup: () => {
    return {};
  }
});
